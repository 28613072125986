import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"


const contact = () => {
  return (
      <Layout>
          <Hero></Hero>
      <section className="contact-page1">
        <article className="contact-form1">
          <h3>CONTACT US</h3>
          <form action="https://formspree.io/f/xoqprpwo"
  method="POST">
            <div className="form-group1">
              <input type="text" name="name" placeholder="name" className="form-control1" required>
              </input>
              <input type="email" name="email" placeholder="email" className="form-control1" required>
              </input>
              <textarea name="message" rows="5" placeholder="message" className="form-control1" required></textarea>
              <button type="submit" className="submit1-btn btn">submit</button>
            </div>
          </form>
        </article>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </section>
      </Layout>
  )
}

export default contact
